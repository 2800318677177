const routesConstants = {
  TEAM_MANAGEMENT: "/team-management",
  TEAM_MANAGEMENT_ADD_USER: "/team-management/add-user",
  TEAM_MANAGEMENT_ADD_ROLE: "/team-management/add-role",
  STATIC_PAGE: "/static-page",
  ADD_STATIC_PAGE: "/static-page/add-page",
  EDIT_STATIC_PAGE: "/static-page/edit",
  SHOP_AND_WIN: "/scan-campaign",
  SHOP_AND_WIN_CREATE_CAMPAIGN: "/scan-campaign/create-campaign",
  SHOP_AND_WIN_EDIT_CAMPAIGN: "/scan-campaign/edit",
  SHOP_AND_WIN_VIEW_CAMPAIGN: "/scan-campaign/view",
  SHOP_AND_WIN_VIEW_USER: "/scan-campaign/view-user",
  SPIN_THE_WHEEL: "/spin-wheel",
  SPIN_THE_WHEEL_CREATE_CAMPAIGN: "/spin-wheel/create",
  SPIN_THE_WHEEL_EDIT_CAMPAIGN: "/spin-wheel/edit",
  SPIN_THE_WHEEL_CLONE_CAMPAIGN: "/spin-wheel/clone",
  SPIN_THE_WHEEL_VIEW_CAMPAIGN: "/spin-wheel/view",
  SPIN_THE_WHEEL_VIEW_USERS: "/spin-wheel/view-users",
  SURVEY: "/survey-manager",
  SURVEY_CREATE: "/survey-manager/create",
  SURVEY_EDIT: "/survey-manager/edit",
  SURVEY_ANALYTICS: "/survey-manager/analytics",
  SCRATCH_CARD: "/scratch-card",
  SCRATCH_CARD_CREATE_CAMPAIGN: "/scratch-card/create",
  SCRATCH_CARD_EDIT_CAMPAIGN: "/scratch-card/edit",
  SCRATCH_CARD_CLONE_CAMPAIGN: "/scratch-card/clone",
  SCRATCH_CARD_VIEW_CAMPAIGN: "/scratch-card/view",
  SCRATCH_CARD_VIEW_USERS: "/scratch-card/view-users",
  RE_OUTCOMES: "/re-outcomes",
  RE_OUTCOMES_CREATE_CAMPAIGN: "/re-outcomes/create",
  RE_OUTCOMES_EDIT_CAMPAIGN: "/re-outcomes/edit",
  RE_OUTCOMES_CLONE_CAMPAIGN: "/re-outcomes/clone",
  RE_OUTCOMES_VIEW_CAMPAIGN: "/re-outcomes/view",
  RE_OUTCOMES_VIEW_USERS: "/re-outcomes/view-users",
  HANDLER_SCAN_HISTORY: "/handler-scan",
};

export default routesConstants;
